<template>
  <v-row>
    <v-col class="loading-spinner-container">
      <v-card class="mb-2">
        <v-card-title class="py-2">
          <v-row align="center">
            <v-col cols="3">
              <v-btn color="primary" @click=addClicked() v-if="isNewAppointmentButtonVisible">
                New Appointment
              </v-btn>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="searchText"
                append-icon="mdi-magnify"
                label="Search"
                @keyup.enter="findClicked"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                @click="findClicked"
                :disabled="isFindButtonDisabled || isLoading"
              > Find </v-btn>
            </v-col>
            <v-col cols="2">
              <v-sheet
                class="text-center mb-1"
                color='#C6E0B4'
              >
                <h5>INBOUND</h5>
              </v-sheet>
              <v-sheet
                class="text-center"
                color='#B4C6E7'
              >
                <h5>OUTBOUND</h5>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card>
        <v-sheet>
          <v-toolbar flat>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-menu
              ref="menu"
              v-model="showMenu"
              :return-value.sync="selectedDate"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-toolbar-title v-on="on">
                  {{ calendarTitle }}
                </v-toolbar-title>
              </template>
              <v-date-picker
                v-model="selectedDate"
                no-title
              >
              <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="showMenu = false"
                >
                  Close
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="viewDay(selectedDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-spacer></v-spacer>
            <v-menu
              bottom
              right
            >
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="74vh">
          <v-calendar
            ref="calendar"
            v-model="focus"
            :events="filteredEvents"
            :event-height=50
            hide-header
            event-overlap-mode="column"
            :first-time="appointmentFirstAvailableTime"
            interval-height="50"
            :interval-count="appointmentIntervalCount"
            :interval-minutes="appointmentIntervalInMinutes"
            :type="type"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            color="red"
            @click:event="eventClicked"
            @change="onCalendarChange"
          >
            <template v-slot:event="{ event }">
              <v-container class="pa-0 d-flex align-center" style="color: black; height: 100%" fluid>
                <v-row no-gutters class="flex-nowrap">
                  <v-col cols="auto" class="mr-2 d-flex align-center">
                    <div>
                      <p class="my-n1 font-weight-bold">{{ event.appointment.number }}</p>
                      <p class="my-n1">{{ getAppointmentTitle(event.appointment) }}</p>
                      <p class="my-n1">{{ event.appointment.createdBy }}</p>
                    </div>
                  </v-col>
                  <v-col class="d-flex align-center">
                    <div>
                      <p class="my-n1">Carrier: {{ event.appointment.equipment.carrier.name }}</p>
                      <p class="my-n1">Load Type: {{ event.appointment.actionType }}</p>
                      <p class="my-n1">{{ getDoorDisplay(event.appointment) }}</p>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-calendar>
          <div v-if="isLoading" class="loading-spinner">
            <v-progress-circular
              v-if="isLoading"
              indeterminate
              color="primary"
              size="64"
              elevation="24"
            ></v-progress-circular>
          </div>
        </v-sheet>
      <v-dialog v-if="showAppointmentEntry" v-model="showAppointmentEntry" persistent max-width="800">
        <appointment-entry></appointment-entry>
      </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import AppointmentEntry from './appointmentEntry.vue';

export default {
  components: { AppointmentEntry },
  data: () => ({
    searchText: '',
    calendarTitle: '',
    selectedDate: '',
    showMenu: false,
  }),
  computed: {
    ...mapGetters('appointment', ['isLoading', 'showAppointmentEntry', 'appointments', 'calendarEvents', 'filteredEvents', 'eventFilter', 'focusedDateString', 'calendarType', 'startDateFilter']),
    ...mapGetters('config', ['appointmentFirstAvailableTime', 'appointmentLastAvailableTime', 'appointmentIntervalInMinutes', 'appointmentIntervalCount', 'maxAppointmentsPerTimeSlot']),
    ...mapGetters('account', ['isAdmin', 'isDeveloper']),
    isFindButtonDisabled() {
      return this.searchText.length < 3;
    },
    isNewAppointmentButtonVisible() {
      return this.isAdmin || this.isDeveloper;
    },
    focus: {
      get() {
        return this.focusedDateString;
      },
      set(value) {
        this.setFocusedDateString(value);
      },
    },
    type: {
      get() {
        return this.calendarType;
      },
      set(value) {
        this.setCalendarType(value);
      },
    },
  },
  methods: {
    ...mapActions('appointment', [
      'getAppointments',
      'getNextAvailableAppointmentNumber',
      'setShowAppointmentEntry',
      'setSelectedAppointment',
      'setStartDateFilter',
      'setEndDateFilter',
      'setEventFilter',
      'setFocusedDateString',
      'setCalendarType',
    ]),
    viewDay(date) {
      this.setFocusedDateString(date);
      this.showMenu = false;
    },
    async addClicked() {
      await this.getNextAvailableAppointmentNumber();
      this.setShowAppointmentEntry(true);
    },
    eventClicked(item) {
      const selectedAppointment = _.cloneDeep(item.event.appointment);
      this.setSelectedAppointment(selectedAppointment);
      this.setShowAppointmentEntry(true);
    },
    findClicked() {
      if (this.searchText !== '') {
        this.setEventFilter(this.searchText);
        this.searchText = this.eventFilter;
      }
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async onCalendarChange({ start, end }) {
      this.setStartDateFilter(start.date);
      this.setEndDateFilter(end.date);
      this.calendarTitle = this.formatCalendarTitle(start.date);
      await this.getAppointments();
    },
    formatCalendarTitle(dateString) {
      const dateObject = new Date(dateString);
      const options = {
        weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', timeZone: 'UTC',
      };
      const formattedDate = dateObject.toLocaleString('en-US', options);
      return formattedDate;
    },
    getAppointmentTitle(appointment) {
      let title = appointment.customer.name;

      if (appointment.loadId !== '') {
        title = `${title} (${appointment.loadId})`;
      }

      return title;
    },
    getDoorDisplay(appointment) {
      if (!appointment.location.displayName || appointment.location.type !== 'Door') {
        return '';
      }
      return `Door: ${appointment.location.displayName}`;
    },
  },
  mounted() {
    this.calendarTitle = this.formatCalendarTitle(this.startDateFilter);
    this.searchText = this.eventFilter;
  },
  watch: {
    searchText(value) {
      if (value === '') {
        this.setEventFilter('');
      }
    },
  },
};

</script>

<style>
.loading-spinner-container {
  position: relative;
}

.loading-spinner {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
</style>
