<template>
  <v-select
    class="pt-0"
    :items="specialHandlingOptions"
    v-model="selectedSpecialHandling"
    label="Special Handling"
    :disabled="!hasEditPermissions"
    multiple
    chips
    deletable-chips
  ></v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import specialHandlingTypes from '../constants/specialHandlingTypes';

export default {
  data: () => ({
    specialHandlingOptions: [specialHandlingTypes.DROP_RAIL_TRAILER],
  }),
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    ...mapGetters('account', ['isAdmin', 'isDeveloper']),
    selectedSpecialHandling: {
      get() {
        return this.selectedAppointment.specialHandling || [];
      },
      set(value) {
        this.setSpecialHandling(value);
      },
    },
    hasEditPermissions() {
      return this.isAdmin || this.isDeveloper;
    },
  },
  methods: {
    ...mapActions('appointment', ['setSpecialHandling']),
  },
};
</script>
