<template>
  <v-card>
    <v-card-title>
      <v-container>
        <v-row>
          <v-col class="pt-0 pb-0">
            <span v-if="selectedAppointment.id">Update Appointment Below:</span>
            <span v-if="!selectedAppointment.id">Add Appointment Below:</span>
          </v-col>
          <v-col class="pt-0 pb-0 d-flex justify-end">
            <span> {{ selectedAppointment.number }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="appointmentEntryForm"
        v-model="validForm"
        :disabled="!hasEditPermissions"
      >
        <v-container fluid>
          <v-row>
            <v-col class="pt-0 pb-0">
              <AppointmentDateEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <RadioEntry property="direction" label="Direction *" :options="directionOptions" action="appointment/setDirection" />
            </v-col>
            <v-col class="pt-0 pb-0">
              <RadioEntry property="actionType" label="Load Type *" :options="actionTypeOptions" action="appointment/setActionType" />
            </v-col>
            <v-col class="pt-0 pb-0">
              <RadioEntry property="transportType" label="Transport Type *" :options="transportTypeOptions" action="appointment/setTransportType" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <AppointmentCustomerEntry />
            </v-col>
            <v-col class="pt-0 pb-0">
              <AppointmentLoadIdEntry />
            </v-col>
            <v-col class="pt-0 pb-0">
              <AppointmentCarrierEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <AppointmentReferenceEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <AppointmentSpecialHandlingEntry />
            </v-col>
            <v-col class="pt-0 pb-0">
              <AppointmentCommentEntry />
            </v-col>
            <v-col class="pt-0 pb-0">
              <AppointmentBolEntry />
            </v-col>
            <v-col class="pt-0 pb-0">
              <AppointmentPickupReadyEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <EquipmentTypeEntry source="appointment" />
            </v-col>
            <v-col class="pt-0 pb-0">
              <EquipmentStateEntry source="appointment" />
            </v-col>
            <v-col class="pt-0 pb-0">
              <EquipmentNumberEntry source="appointment" v-bind:requiresValidation=false v-bind:allowAutocomplete=false />
            </v-col>
            <v-col class="pt-0 pb-0">
              <EquipmentSizeEntry source="appointment" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <AppointmentTrailerConditionEntry />
            </v-col>
            <v-col class="pt-0 pb-0">
              <AppointmentSealEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <AppointmentLocationEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <AppointmentDriverEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn class="mr-4" min-width="7vw" color="primary" @click="saveClicked" v-if="isSaveButtonVisible"> Save </v-btn>
              <v-btn class="mr-4" min-width="7vw" color="primary" @click="updateClicked" v-if="isUpdateButtonVisible"> Update </v-btn>
              <v-btn class="mr-4" min-width="7vw" color="secondary" @click="cancelClicked" v-if="isCancelButtonVisible"> Cancel </v-btn>
              <v-btn min-width="7vw" @click="closeClicked"> Close </v-btn>
            </v-col>
          </v-row>
          <v-dialog v-if="showCancelConfirmation" v-model="showCancelConfirmation" persistent max-width="800">
            <verify-appointment-cancel v-on:appointmentCanceled="reset"></verify-appointment-cancel>
          </v-dialog>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import AppointmentDateEntry from './appointmentDateEntry.vue';
import RadioEntry from './appointmentRadioEntry.vue';
import AppointmentBolEntry from './appointmentBolEntry.vue';
import AppointmentCustomerEntry from './appointmentCustomerEntry.vue';
import AppointmentCarrierEntry from './appointmentCarrierEntry.vue';
import AppointmentReferenceEntry from './appointmentReferenceEntry.vue';
import AppointmentCommentEntry from './appointmentCommentEntry.vue';
import AppointmentTrailerConditionEntry from './appointmentTrailerConditionEntry.vue';
import AppointmentSealEntry from './appointmentSealEntry.vue';
import AppointmentLoadIdEntry from './appointmentLoadIdEntry.vue';
import AppointmentLocationEntry from './appointmentLocationEntry.vue';
import AppointmentDriverEntry from './appointmentDriverEntry.vue';
import AppointmentPickupReadyEntry from './appointmentPickupReadyEntry.vue';
import EquipmentTypeEntry from './equipmentTypeEntry.vue';
import EquipmentStateEntry from './equipmentStateEntry.vue';
import EquipmentNumberEntry from './equipmentNumberEntry.vue';
import EquipmentSizeEntry from './equipmentSizeEntry.vue';
import VerifyAppointmentCancel from './verifyAppointmentCancel.vue';
import AppointmentSpecialHandlingEntry from './appointmentSpecialHandlingEntry.vue';
import direction from '../constants/direction';
import actionTypes from '../constants/actionTypes';
import transportTypes from '../constants/transportTypes';
import appointmentMapper from '../mappers/appointmentMapper';

export default {
  components: {
    AppointmentDateEntry,
    RadioEntry,
    AppointmentBolEntry,
    AppointmentCustomerEntry,
    AppointmentCarrierEntry,
    AppointmentReferenceEntry,
    AppointmentCommentEntry,
    AppointmentTrailerConditionEntry,
    AppointmentSealEntry,
    AppointmentLoadIdEntry,
    AppointmentLocationEntry,
    AppointmentDriverEntry,
    AppointmentPickupReadyEntry,
    EquipmentTypeEntry,
    EquipmentStateEntry,
    EquipmentNumberEntry,
    EquipmentSizeEntry,
    VerifyAppointmentCancel,
    AppointmentSpecialHandlingEntry,
  },
  data: () => ({
    validForm: true,
    directionOptions: [direction.IN, direction.OUT],
    actionTypeOptions: [actionTypes.DROP_AND_HOOK, actionTypes.LIVE],
    transportTypeOptions: [transportTypes.FTL, transportTypes.LTL],
  }),
  computed: {
    ...mapGetters('appointment', ['selectedAppointment', 'showCancelConfirmation']),
    ...mapGetters('account', ['fullName', 'isAdmin', 'isDeveloper']),
    isSaveButtonVisible() {
      const hasAppointmentId = this.selectedAppointment.id;

      return !hasAppointmentId && this.hasEditPermissions;
    },
    isUpdateButtonVisible() {
      const hasAppointmentId = this.selectedAppointment.id;

      return hasAppointmentId && this.hasEditPermissions;
    },
    isCancelButtonVisible() {
      const hasAppointmentId = this.selectedAppointment.id;

      return hasAppointmentId && this.hasEditPermissions;
    },
    hasEditPermissions() {
      return this.isAdmin || this.isDeveloper;
    },
  },
  methods: {
    ...mapActions('appointment', [
      'setSelectedAppointment',
      'setAppointmentCreatedBy',
      'setShowAppointmentEntry',
      'setShowCancelConfirmation',
      'addAppointment',
      'updateAppointment']),
    saveClicked() {
      const isValid = this.$refs.appointmentEntryForm.validate();
      if (isValid) {
        this.setAppointmentCreatedBy(this.fullName);
        this.addAppointment(this.selectedAppointment);
        this.reset();
      }
    },
    updateClicked() {
      const isValid = this.$refs.appointmentEntryForm.validate();
      if (isValid) {
        const request = {
          appointment: this.selectedAppointment,
          updatedBy: this.fullName,
        };
        this.updateAppointment(request);
        this.reset();
      }
    },
    cancelClicked() {
      this.setShowCancelConfirmation(true);
    },
    closeClicked() {
      this.reset();
    },
    reset() {
      this.setSelectedAppointment(_.cloneDeep(appointmentMapper.defaultAppointment));
      this.$refs.appointmentEntryForm.resetValidation();
      this.setShowCancelConfirmation(false);
      this.setShowAppointmentEntry(false);
    },
  },
};
</script>
